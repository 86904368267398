import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentType:"1",
    activeIndex:'/',
    activeName:"first",//关于我们
    about:{
      title:"致力于打造金融科技生态的参与者",
      value:"Participants committed to building a fintech ecosystem"
    }, //关于我们的文案
    activeProduct:"first",
    activeInformation:"first",
    leftOffset:0,
    bannerOpacity:1
  },
  mutations: {
     // 当前会话
     setCurrentType(state,currentType){
      state.currentType = currentType;
    },
    // header 点击
    setActiveIndex(state,activeIndex){
      state.activeIndex = activeIndex;
    },
    //关于我们
    setActiveName(state,activeName){
      state.activeName = activeName;
    },
    setAbout(state,about){
      state.about = about;
    },
    setActiveProduct(state,activeProduct){
      state.activeProduct = activeProduct;
    },
    setActiveInformation(state,activeInformation){
      state.activeInformation = activeInformation;
    },
    setLeftOffset(state,leftOffset){
      state.leftOffset = leftOffset;
    },
    setBannerOpacity(state,bannerOpacity){
      state.bannerOpacity = bannerOpacity;
    }
  },
  actions: {
  },
  modules: {
  },
  getters:{
     // 当前会话
     getCurrentType:state=>{
      return state.currentType
    },
    getActiveIndex:state=>{
      return state.activeIndex
    },
    getActiveName:state=>{
      return state.activeName
    },
    getAbout:state=>{
      return state.about;
    },
    getActiveProduct:state=>{
      return state.activeProduct ;
    },
    getActiveInformation:state=>{
      return state.activeInformation;
    },
    getLeftOffset:state=>{
      return state.leftOffset;
    },
    getBannerOpacity:state=>{
     return state.bannerOpacity
    }
  }

})
