import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/homePage/index.vue'),
    meta:{
      title:'瑞智科技-首页',
      content:{
        keywords:"瑞智科技，财瑞通，财富管理平台，私募基金，私募证券，海外保险",
        description:"瑞智科技通过利用移动互联网、大数据、AI人工智能、云服务、视频通讯、区块链等先进的技术， 立足于金融产业集自主研发、信息技术咨询与推广、运营管理于一体的创新型科技企业。专注于为金融产业提供科技服务，目前已推出财瑞通，致力于为资产机构、财富机构、投资顾问、投资人提供综合性资产与财富管理服务平台。"
      }
    }
  },
  // 关于我们
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutMe/index.vue'),
    meta:{
      title:'瑞智科技-关于我们',
      content:{
        keywords:"瑞智科技-企业文化，财瑞通，财富管理平台，私募基金，私募证券，海外保险，瑞智科技-关于我们，招贤纳士，瑞智科技-企业文化，瑞智科技-联系我们",
        description:"成都瑞智科技有限公司是一个专注互联网、大数据、AI人工智能、云服务、视频通讯、区块链等先进的技术， 立足于金融产业集自主研发、信息技术咨询与推广、运营管理于一体的创新型科技企业。专注于为金融产业提供科技服务，目前已推出财瑞通，致力于为资产机构、财富机构、投资顾问、投资人提供综合性资产与财富管理服务平台。加入瑞智科技，一起为社会创造价值，为科技创造财富。"
      }
    }
  },
  // 产品服务
  {
    path: '/productService',
    name: 'productService',
    component: () => import(/* webpackChunkName: "about" */ '../views/productService/index.vue'),
    meta:{
      title:'瑞智科技-产品服务',
      content:{
        keywords:"财富管理机构，资产管理机构，独立家族办公室，独立基金销售公司，三方财富机构，券商财富中心，保险代理销售公司，私募/公募基金管理人，基金子公司，银行理财子公司，企业融资方，保险资管公司，独立资管团队/FA",
        description:"财瑞通财富管理平台，提供APP、小程序等多个平台供用户使用，平台提供多种服务，全方位为客户服务，为社会创造价值，为客户提供财富"
      }
    }
  },
  // 新闻资讯
  {
    path: '/dynamicInformation',
    name: 'dynamicInformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/dynamicInformation/index.vue'),
    meta:{
      title:'瑞智科技-资讯动态',
      content:{
        keywords:"财富前沿分享，财富资讯，财富早报，财富新闻，私募基金最新动态，私募基金最新报告，中国财富未来估值，全球经济市场变化",
        description:"财瑞通财富管理平台，财富最新资讯，基金今日最新动态，私募股权今日发行动态，深圳大盘指数，上海证券交易所"
      }
    }
  },
  //市场合作
  {
    path: '/marketCooperation',
    name: 'marketCooperation',
    component: () => import(/* webpackChunkName: "about" */ '../views/marketCooperation/index.vue'),
    meta:{
      title:'瑞智科技-市场合作',
      content:{
        keywords:"财富合作，私募证券，私募基金，海外保险签约，保险，基金，买基金,创造财富",
        description:"买基金的平台，私募证券国内交易中心，全国较好的私募机构，私募股权交易合作。"
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
router.beforeEach((to, from, next)=>{
  if(to.meta.title) {   //路由发生变化时候修改页面中的title
      document.title = to.meta.title
  }
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector("meta[name='keywords']").setAttribute('content',to.meta.content.keywords)
    document.querySelector("meta[name='description']").setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  next()
})